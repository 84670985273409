import './App.css';
import React, { useState, useEffect } from 'react';
import { auth, provider } from './firebase';
import { signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import MathApp from './MathApp';


function App() {

  const [user, setUser] = useState(null);
  const [userID, setUserID] = useState(null);

  const generatePairs = (conversionSet, pairs) => {
    for (let key in conversionSet){
        for (let key2 in conversionSet){
            if (key !== key2){
                pairs.push(conversionSet[key] + '-' + conversionSet[key2])
            }             
        }
    }           
}


const generateConversions = () => {

    const lengthUnits = [
  'm', 'km', 'hm', 'dam', 'dm', 'cm', 'mm', 'μm', 'nm', 'pm', 'fm', 'am',
  'in', 'ft', 'yd', 'mi', 'Å', 'au', 'ly', 'pc',
  'Earth_radius', 'Moon_radius', 'Sun_radius', 'Jupiter_radius'
];
    const timeUnits = [
      'as', 'fs', 'ps', 'ns', 'μs', 'ms', 's', 'min', 'hr',
      'day', 'week', 'fortnight', 'month', 'year', 'decade', 'century', 'millennium',
      'jiffy', 'shake', 'sidereal_day', 'tropical_year', 'lunar_month'
    ];
    const temperatureUnits = ['K', 'C', 'F', 'R'];
    const volumeUnits = [
      'L', 'mL', 'dL', 'cL', 'μL', 'nL',
      'gallon', 'pint', 'quart', 'cup', 'fl_oz', 'tbsp', 'tsp'
    ];
    const massUnits = [
      'kg', 'g', 'mg', 'μg', 'ng', 'pg', 'fg',
      'lb', 'oz', 'st',
      'proton_mass', 'electron_mass', 'hydrogen_mass', 'neutron_mass', 'amu',
      'Earth_mass', 'Moon_mass', 'Sun_mass'
    ];

    const angleUnits = ['°', 'rad']
    const energyUnits = [
      'J', 'kJ', 'MJ', 'GJ', 'TJ', 'cal', 'kcal', 'eV', 'kWh',
      'MWh', 'GWh', 'TWh', 'PWh', 'kWh_day', 'MWh_day', 'GWh_day',
      'kWh_year', 'MWh_year', 'GWh_year', 'TWh_year'
    ];
    const currencies = [
      'AUD', 'BRL', 'CAD', 'CHF', 'CNY', 'EUR', 'GBP', 'HKD', 'INR', 'JPY',
      'KRW', 'MXN', 'NOK', 'NZD', 'RUB', 'SEK', 'SGD', 'ZAR', 'TRY', 'ILS', 'USD'
    ];

    const allUnits = [
      lengthUnits, timeUnits, temperatureUnits, volumeUnits, massUnits, angleUnits, energyUnits, currencies
    ];


    let pairs = [];
    for (const unitSet of allUnits) {
        generatePairs(unitSet, pairs);
    }
    console.log(pairs)
    return pairs
}

const conversions = generateConversions()
console.log(conversions)


  useEffect(() => {
    // Monitor authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUserID(user.uid);
          // You can now use userEmail as the key for your database
        } else {
          // User is signed out
          setUserID(null);
        }
      });
    });
    return () => unsubscribe();
  }, []);

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        setUserID(user.uid);
        console.log('User signed in:', user);
      })
      .catch((error) => {
        console.error('Error during Google Sign-In:', error.message);
      });
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUserID(null)
        console.log('User signed out');
      })
      .catch((error) => {
        console.error('Error during sign out:', error.message);
      });
  };

  return (
    <div className="App">
        {user ? (
          <div className="sign-out-container">
          <button className="sign-out-button" onClick={handleSignOut}>
          Sign Out
          </button>
          </div>) : (
            <div></div>
        )}
      <div className="container">
        {!user ? (
          <div className="auth-container">
            <h1>OptiCognito Sign In</h1>
            <button className="sign-in-button" onClick={handleSignIn}>
              Sign In with Google
            </button>
          </div>
        ) : (
          <div className="auth-container">
           <MathApp userID= {userID} conversions={conversions}/> 
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
