import React, { useState, useEffect } from 'react';

function EloTime({userID}) {
  const [eloTime, setEloTime] = useState(0);

  useEffect(() => {
    const fetchEloTime = async () => {
      try {
        const response = await fetch(`https://api.opticognito.au/get-elo-time/${userID}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setEloTime(data.eloTime || 0); // Handle case where eloTime might be missing
      } catch (error) {
        console.error("Error fetching initial Elo time:", error);
        // Consider displaying an error message to the user
      }
    };

    if (userID) { //Only fetch if userID is available
      fetchEloTime();
    }
  }, [userID]); //useEffect runs only when userID changes

  const changeEloTime = async (event) => { //Added event parameter

    const newEloTime = parseInt(event.target.value, 10) || 0; // Parse as integer, handle empty
    setEloTime(newEloTime); // Update state immediately for visual feedback

    const response = await fetch('https://api.opticognito.au/change-elo-time', {
            method: 'POST',
            body: JSON.stringify({
                userID: userID,
                eloTime: newEloTime
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    const debouncedChangeEloTime = debounce(changeEloTime, 1000); //Debounce the function

    function debounce(func, timeout = 1000) {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func.apply(this, args);
          }, timeout);
        };
      }

  return (
    <div>
      Elo Time: <input type='number' id='eloTime' onInput={changeEloTime} value={eloTime} />
    </div>
  );
}

export default EloTime;